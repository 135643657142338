import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout/layout";
import PortableText from '@sanity/block-content-to-react';
import {serializers} from "../utils/portableTextSerializers";
import SEO from "../components/layout/seo";
import SectionWrapper from "../components/layout/sectionWrapper";
import SectionContainer from "../components/layout/sectionContainer";
import CustomGatsbyImage from "../components/global/customGatsbyImage";

const NewShow = ({data}) => {
  const news = data.sanityNews;
  return (
    <Layout>
      <SEO title={news.title} />
      <SectionWrapper>
        <SectionContainer customClasses="py-16 md:py-20 lg:pt-32 lg:pb-40 w-prose items-center flex flex-col">
          <div className="flex flex-col items-center text-xl lg:max-w-prose">
            <div className="relative">
              <div className="absolute bottom-0 z-10 px-6 py-2 font-bold text-gray-700 -left-4 bg-primary">
                <h1 className="text-xl font-bold text-gray-700 uppercase md:text-2xl sm:text-3xl lg:text-3xl ">{news.title}</h1>
              </div>
              <CustomGatsbyImage image={news.image} alt="Illustration de l'actualité" />
            </div>
            <div className="self-center mt-6">
              <p className="text-gray-700">Publié le {news.date}</p>
              <div className="w-full h-1 mt-4 rounded bg-secondary" />
            </div>
          </div>
          <div className="mt-8 text-xl prose xl:prose-xl">
            <PortableText
              blocks={news._rawContent}
              serializers={serializers}
            />
          </div>
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  );
}

export default NewShow;

export const query = graphql`
  query NewsShow ($newsId: String!) {
    sanityNews(id: { eq: $newsId }) {
      _id
      title
      _rawContent
      date
      image {
        asset {
          gatsbyImageData(width: 1200)
        }
        hotspot {
          x
          y
        }
      }
    }
  }
`;